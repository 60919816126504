
import CertificationImage from "../site-components/svgs/oshaLogo.svg"
import SafetyImage from "../site-components/images/safety-job_site.jpg"

const SafetyConfig =
{
    bigHeader: "SAFETY TRAINING",
    subHeader: "No job is so important and no service is so urgent that we cannot take time to perform our work safely.",
    sections: [
        {
            title: "OUR COMMITMENT",
            id: 'commitment',
            description: [
                "At Superior Land Designs, the safety of our employees, clients, and the public is our top priority. Our commitment to creating a safe and secure work environment starts at the very top of our organization and extends throughout all levels.",
                "We believe that an effective safety culture is built on strong leadership and clear communication. Our executive team and managers are fully dedicated to promoting and prioritizing safety in all aspects of our operations. They lead by example, demonstrating the importance of following safety protocols and encouraging open dialogue about any potential risks or hazards.",
                "To ensure the well-being of our team members, we provide comprehensive safety training and ongoing education programs. All employees undergo rigorous safety training upon joining our company and participate in regular refresher courses to stay updated on the latest safety procedures and practices.",
                "Furthermore, we have implemented a robust safety management system that includes regular safety audits, inspections, and reporting mechanisms. This enables us to proactively identify and address any potential safety concerns before they turn into incidents or accidents.",
                "In addition to our internal safety measures, we also prioritize safety in our interactions with clients and the public. We ensure that our projects adhere to all relevant safety regulations and guidelines, and we provide clear signage and warnings when necessary. Our goal is to not only protect our employees but also foster a safe environment for all stakeholders involved in our projects.",
                "Safety is not just a checkbox or a legal requirement - it is an integral part of our company culture at Superior Land Designs. We firmly believe that prioritizing safety leads to increased productivity, employee satisfaction, and successful project outcomes."
            ],
        },
        {
            title: "TRAINING SERVICES",
            id: 'training-services',
            description: [
                "The best way to ensure the safety and compliance of our employees is by giving them the proper training they need. Superior Land Designs is committed to providing the very best in classroom training for all of our employees. Courses are taught at our classroom location in Alpharetta, Georgia.",
                "Our Safety Managers remain current through certifications with our partner organizations and continuing education through the Georgia Institute of Technology."
            ],
            image: SafetyImage,
            imageAltText: "SLD personnel using safe practices on a job site",
        },
        {
            title: "AVAILABLE CERTIFICATIONS",
            id: 'available-certification',
            list: [
                {
                    navigationText: "OSHA 10 and 30 hour for Construction",
                    navigationURL: "/Safety-OSHA"
                },
                {
                    navigationText: "Competent Person Training For Excavations",
                    navigationURL: "/Safety-Excavations"
                },
                {
                    navigationText: "NCCER Operator Qualification Training and Evaluations",
                    navigationURL: "/Safety-Operator"
                },
                {
                    navigationText: "HAZWOPER 8 Hour Refresher",
                    navigationURL: "/Safety-HAZWOPER"
                },
            ],
            slider: [
                "Providing Best in Class Services to the Petroleum & Natural Gas Industry",
                "The Superior Advantage - One Stop. One Company",
                "Safety is a small investment for a rich future",
                "A 21st Century Company",
                "Helping Fuel the American needs with Competent and Qualified Safety Inspection"
            ],
            //TODO: modify navigationURL to reflect "Safety-Certification"
            //      (so this page can be the "Safety" home)
            navigationText: "Explore the Course Catalog",
            navigationURL: "/Safety-Certifications",
            image: CertificationImage,
            imageAltText: "OSHAlogo",
        },
    ]

}

export default SafetyConfig