import DescriptionImage from "../site-components/images/surveying-river-crossing.jpg"

const ServicesSurveyingConfig =
{
    topHeader: "PROFESSIONAL SURVEYING",
    subHeader: "Expert site evaluation on land or by sea for pipeline construction.",
    description: [
        "Superior Land Designs was formed in 2003 as a survey firm. By 2008, we began focusing on the specific needs of the pipeline industry. SLD has worked on many different projects including pipeline relocations, tank dike improvements, as well as performing annual hydrographic inspections for navigable waterways. We utilize and invest in the latest survey technology to provide our clients with the best and most accurate data. Our licensed surveyors have the background and knowledge to make your project a success. ",
        "Superior Land Designs utilizes state of the art GPS survey equipment to locate, inspect and gauge the integrity of each utility company we work for. Our Survey experience and knowledge come from years of experience constructing utilities. ",
        "Superior Land Designs performs Hydrographic Surveys for pipeline companies as well as large or small agencies. The equipment used is a cutting-edge instrumentation, ancillary hardware, and remote survey platform. This equipment integrates conventional survey and provides data that is tied into a Global Positioning System (GPS). By minimizing the amount of personnel needed on each survey this increases field efficiency as well as improving the Safety of our employees. ",
        "SLD has completed more than 500 Hydrographic Surveys utilizing skiff boats, inflatable boats-canoes, and flat bottom boats. This enables us to navigate in waterways typically inaccessible. ",
    ],
    services: {
        header: "Available Services:",
        list: [
            "Topographic Survey",
            "Route Survey (Prelim and As-built)",
            "Control Survey",
            "Pipe Movement Survey",
            "Hydrographic Inspections",
            "ROW Survey",
            "Utility Survey",
        ],
        image: DescriptionImage,
        imageAltText: "SLD personnel conduct a hydrographic survey.",
    },
    contactHeader: "Contact us today for a consultation on your surveying project.",
    backNavigationText: "Back to Services",
    backNavigationURL: "/Services",
}
export default ServicesSurveyingConfig