import React from 'react';
import ServicesConfig from 'localization/ServicesConfig';
import RightArrow from '../site-components/svgs/RightArrow.svg'
import './Services.scss'
import { useMediaQuery } from '@material-ui/core';

export default function Services() {
    const matches = useMediaQuery('(max-width:480px)');

    return <div className="container">
        <div className="header--wrapper header--wrapper-mobile">
            <h1 className="header--big">
                {ServicesConfig.bigHeader}
            </h1>
            <h1>{ServicesConfig.subHeader}</h1>
        </div>
        <div className="row" id='expertise'>
            <h2>{ServicesConfig.expertise.title}</h2>
            <p dangerouslySetInnerHTML={{ __html: ServicesConfig.expertise.description }} />
        </div>
        <div className="row">
            {!matches ? <div className="service--wrapper">
                <div className="left--col">
                    <h2 className="title">{ServicesConfig.infrastructure.title}</h2>
                    <p className='desc'> {ServicesConfig.infrastructure.description}</p>
                    <a href={ServicesConfig.infrastructure.navigationURL}>
                        <span className="navigation--text">
                            {ServicesConfig.infrastructure.navigationText} <img src={RightArrow} alt={RightArrow} />
                        </span>
                    </a>
                </div>
                <figure className="right--col">
                    <img className='image' src={ServicesConfig.infrastructure.image} alt={ServicesConfig.infrastructure.imageAltText} />
                </figure>
            </div>
                :
                <div className="service--wrapper">
                    <h2 className="title">{ServicesConfig.infrastructure.title}</h2>
                    <figure >
                        <img className='image' src={ServicesConfig.infrastructure.image} alt={ServicesConfig.infrastructure.imageAltText} />
                    </figure>
                    <p> {ServicesConfig.infrastructure.description}</p>
                    <a href={ServicesConfig.infrastructure.navigationURL}>
                        <span className="navigation--text">
                            {ServicesConfig.infrastructure.navigationText} <img src={RightArrow} alt={RightArrow} />
                        </span>
                    </a>
                </div>
            }

        </div>
        <div className="row">
            {!matches ? <div className="service--wrapper">
                <div className="left--col">
                    <h2 className="title">{ServicesConfig.engineering.title}</h2>
                    <p className='desc'>{ServicesConfig.engineering.description}</p>
                    <a href={ServicesConfig.engineering.navigationURL}>
                        <span className="navigation--text">
                            {ServicesConfig.engineering.navigationText} <img src={RightArrow} alt={RightArrow} />
                        </span>
                    </a>
                </div>
                <figure className="right--col">
                    <img className='image' src={ServicesConfig.engineering.image} alt={ServicesConfig.engineering.imageAltText} />
                </figure>
            </div>
                :
                <div className="service--wrapper">
                    <h2 className="title">{ServicesConfig.infrastructure.title}</h2>
                    <figure >
                        <img className='image' src={ServicesConfig.engineering.image} alt={ServicesConfig.engineering.imageAltText} />
                    </figure>
                    <p> {ServicesConfig.engineering.description}</p>
                    <a href={ServicesConfig.engineering.navigationURL}>
                        <span className="navigation--text">
                            {ServicesConfig.engineering.navigationText} <img src={RightArrow} alt={RightArrow} />
                        </span>
                    </a>
                </div>
            }

        </div>
        <div className="row">
            {!matches ? <div className="service--wrapper">
                <div className="left--col">
                    <h2 className="title">{ServicesConfig.surveying.title}</h2>
                    <p className='desc'> {ServicesConfig.surveying.description}</p>
                    <a href={ServicesConfig.surveying.navigationURL}>
                        <span className="navigation--text">
                            {ServicesConfig.surveying.navigationText} <img src={RightArrow} alt={RightArrow} />
                        </span>
                    </a>
                </div>
                <figure className="right--col">
                    <img className='image' src={ServicesConfig.surveying.image} alt={ServicesConfig.surveying.imageAltText} />
                </figure>
            </div>
                :
                <div className="service--wrapper">
                    <h2 className="title">{ServicesConfig.infrastructure.title}</h2>
                    <figure >
                        <img className='image' src={ServicesConfig.surveying.image} alt={ServicesConfig.surveying.imageAltText} />
                    </figure>
                    <p> {ServicesConfig.surveying.description}</p>
                    <a href={ServicesConfig.surveying.navigationURL}>
                        <span className="navigation--text">
                            {ServicesConfig.surveying.navigationText} <img src={RightArrow} alt={RightArrow} />
                        </span>
                    </a>
                </div>
            }

        </div>
        <div className="row">
            <h2 className="row__header">{ServicesConfig.projects.title}</h2>
            <p> {ServicesConfig.projects.description}</p>
            <a href={ServicesConfig.projects.navigationURL}>
                <span className="navigation--text">
                    {ServicesConfig.projects.navigationText} <img src={RightArrow} alt={RightArrow} />
                </span>
            </a>
        </div>

    </div>
}
//TODO_UX: Likely need image for Projects 