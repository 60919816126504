import DescriptionImage from "../site-components/images/proj2-tankfarm.jpg"
import ProjectImage from "../site-components/images/proj1-braves.png"

const ServicesEngineeringConfig =
{
    topHeader: "PIPELINE DESIGN ENGINEERING",
    subHeader: "A trusted partner for building new or existing pipeline construction.",
    description: [
        "Superior Land Designs has been engineering and designing pipeline infrastructure since 2008. We provide RARE solutions (Reliable, Accountable, Responsible, and Efficient) for both government and private sector clients. Whether your plans include improving existing infrastructure or are adding to your footprint, we can assist you in that process. Our engineers have extensive experience in the pipeline industry and provide detailed CADD drawings that can be easily executed in the field.",
    ],
    services: {
        header: "Available Services:",
        list: [
            "Relocation Survey and Design",
            "Process Piping",
            "Pump Station Modifications",
            "Tank Farm Construction",
            "Tank Rehabilitation",
            "Permitting",
        ],
        image: DescriptionImage,
        imageAltText: "Tank farm construction example."
    },
    project_featured: {
        header: "Featured Project: Truist Park (Braves) Line Relocation",
        description: [
            "Superior Land Designs and our client Pipeline Contractor completed 2600 linear feet of 36\" and 40\" petroleum line relocation in support of the construction of Truist Park for the Atlanta Braves in Cobb County, Georgia. ",
            "SLD provided Sub Surface Utility Engineering, Utility Locations, Utility Permits, Project Management and Inspections as well as coordinated between Pipeline Owner, Pipeline Construction crews and Civil Construction crews to ensure client timelines were met and all safety standards were being used.",
        ],
        image: ProjectImage,
        imageAltText: "SLD personnel preparing a section of pipe for the Truist Park line.",
        navigationText: "View Projects",
        navigationURL: "/Projects", 
    },
    contactHeader: "Contact us today for a consultation on your construction project.",
    backNavigationText: "Back to Services",
    backNavigationURL: "/Services",
}
export default ServicesEngineeringConfig