import InfrastuctureImage from "../site-components/images/inspection-pipeline.jpg"
import EngineeringImage from "../site-components/images/pipeline_project.jpg"
import SurveyImage from "../site-components/images/surveying_row.jpg"

const ServicesConfig =
{
    bigHeader: "PIPELINE SERVICES",
    subHeader: "No matter the size and scope of your pipeline project, we have inspectors with the knowledge and skill to make it successful.",

    expertise: {
        title: "OUR EXPERTISE",
        description: "Superior Land Designs offers Construction Inspection and Quality Control Personnel to the Petroleum and Natural Gas Industry along with Surveying and Engineering expertise. Our Management <a class='team-link' href='/About#history'>TEAM</a> consists of experienced people with both field and administration experience. Our <a class='team-link' href='/About#history'>TEAM</a>, known in the industry for quality services, will maintain this philosophy as pipeline safety advances. Pipeline Route Selection and Surveying Services are also a strong service we offer.",
    },
    infrastructure: {
        title: "Pipeline Infrastructure Inspections",
        description: "Superior Land Designs recognizes the fact that we are only as good as the people we employ. To that end, we reinvest a significant portion of SLD resources to employee training and continuing education. We have found this leads to greater employee retention and enables us to offer the very best inspection services in the industry. Many of our inspectors have attained additional certificates of training including: API 1169, NACE CIP, and NACE CWI to name a few.",
        navigationText: "View Infrastructure Inspection Services",
        navigationURL: "/Services-Inspections",
        image: InfrastuctureImage,
        imageAltText: "Infrastructure Image",
    },
    engineering: {
        title: "Pipeline Engineering Services",
        description: "Superior Land Designs has been engineering and designing pipeline infrastructure since 2008. We provide RARE solutions (Reliable, Accountable, Responsible, and Efficient) for both government and private sector clients. Whether your plans include improving existing infrastructure or are adding to your footprint, we can assist you in that process. Our engineers have extensive experience in the pipeline industry and provide detailed CADD drawings that can be easily executed in the field.",
        navigationText: "View Pipeline Engineering Services",
        navigationURL: "/Services-Engineering",
        image: EngineeringImage,
        imageAltText: "Engineering Image",
    },
    surveying: {
        title: "Professional Surveying",
        description: "Superior Land Designs was formed in 2003 as a survey firm. In 2008 we began focusing on the specific needs of the pipeline industry. SLD has worked on many different projects including pipeline relocations, tank dike improvements, as well as perform annual hydrographic inspections for navigable waterways. We utilize and invest in the latest survey technology to provide our clients with the best and most accurate data. Our licensed surveyors have the background and knowledge to make your project a success.",
        navigationText: "View Professional Surveying Services",
        navigationURL: "/Services-Surveying",
        image: SurveyImage,
        imageAltText: "Survey Image",
    },
    projects: {
        title: "Project Examples",
        description: "See how Superior Land Designs has helped some of our public and private sector clients.",
        navigationText: "View Project Examples",
        navigationURL: "/Projects",
    },
}


export default ServicesConfig