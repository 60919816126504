import DescriptionImage from "../site-components/images/scanning_pipeline.jpg"

const ServicesInspectionsConfig =
{
    topHeader: "INFRASTRUCTURE INSPECTIONS",
    subHeader: "No matter the size and scope of your pipeline project, we have inspectors with the knowledge and skill to make it successful.",
    description: [
        "Superior Land Designs believes our country is only as good as our infrastructure. The integrity of our clients’ assets and the country’s infrastructure is our greatest priority. When the population and need for expansion grows, so does the need to relocate existing utilities. We have been modifying, remodeling, and designing utility relocations on over 75 projects. Superior Land Designs is a turnkey company that handles projects from conception to preliminary design and construction management. Our years of experience in the public/private sector has built a vast amount utility contact to which integrate the transition and growth of the infrastructure.",
    ],
    services: {
        header: "Available Services:",
        list: [
            "Pipeline Integrity Inspection",
            "NACE Coating Inspection",
            "ROW Inspection",
            "Tank Inspection (new build and repair)",
            "Safety Inspection",
        ],
        image: DescriptionImage,
        imageAltText: "Pipeline scanning",
    },
    contactHeader: "Contact us today for a consultation on your inspection needs.",
    backNavigationText: "Back to Services",
    backNavigationURL: "/Services",
}
export default ServicesInspectionsConfig