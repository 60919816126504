import React, { useState, useEffect } from "react";
import { Grid, Container } from '@material-ui/core';
import _ from "lodash";
import Fade from 'react-reveal/Fade';
import PortfolioConfig from 'localization/PortfolioConfig';
import './Project.scss'
import RightArrow from '../site-components/svgs/RightArrow.svg'
import SLDGallery from 'site-components/SLDGallery';
import { useHistory, useLocation } from 'react-router-dom';
import { BlobServiceClient } from '@azure/storage-blob';

export default function Projects() {
    const [activeProject, setActiveProject] = useState();
    const [primaryPhotos, setPrimaryPhotos] = useState([]);
    const [thumbNailPhotos, setThumbNailPhotos] = useState([]);
    const location = useLocation();
    const history = useHistory();
    const containerURL = "https://sldprodcontentstorage.blob.core.windows.net"

    useEffect(() => {
        listBlobs(true)
        listBlobs()
    }, []);

    const listBlobs = async (isThumbNail) => {
        const containerName = !isThumbNail ? "projects" : "projectsthumbnail"
        const cachedData = !isThumbNail ? sessionStorage.getItem('projectPhotos') : sessionStorage.getItem('projectThumbNail');
        if (cachedData) {
            const results = JSON.parse(cachedData);
            if (isThumbNail) {
                setThumbNailPhotos(results)
            } else
                setPrimaryPhotos(results)
        } else {

            const blobServiceClient = new BlobServiceClient(containerURL)
            const containerClient = blobServiceClient.getContainerClient(containerName);

            const projectPhotos = PortfolioConfig.primaryImages.map(async (x) => {
                const blobItems = [];
                for await (const blob of containerClient.listBlobsFlat({ prefix: x.directory })) {
                    const img = new Image();
                    const loadImage = new Promise((resolve) => {
                        // Set the src attribute to the blob URL
                        img.src = `${containerURL}/${containerName}/${blob.name}`;
                        img.onload = () => resolve(img);
                    });
                    let imgresult = await loadImage;
                    blobItems.push({
                        src: `${containerURL}/${containerName}/${blob.name}`,
                        height: imgresult.height / 100,
                        width: imgresult.width / 100,
                    })
                }
                if (!isThumbNail) {
                    setPrimaryPhotos((prev) => {
                        return [...prev,
                        {
                            ...x,
                            blobs: blobItems
                        }
                        ]
                    })
                } else {
                    setThumbNailPhotos((prev) => {
                        return [...prev,
                        {
                            ...x,
                            blobs: blobItems
                        }]
                    })
                }
                return {
                    ...x,
                    blobs: blobItems
                }

            })
            const results = await Promise.all(projectPhotos);

            if (isThumbNail) {
                sessionStorage.setItem('projectThumbNail', JSON.stringify(results));
            } else {
                sessionStorage.setItem('projectPhotos', JSON.stringify(results));
            }
        }

    }

    useEffect(() => {
        if (location.hash) {
            setActiveProject(location.hash.replace('#', ''))
        } else {
            setActiveProject(null)

        }
    }, [location])

    const handleActiveProject = (id) => {
        setActiveProject(id);
    }

    return (
        <>

            <div className="container project--container">
                <Container className="">
                    <div className="">
                        {!activeProject &&
                            <div className="header--wrapper header--wrapper-details">
                                <h1 className='header--big'>
                                    {PortfolioConfig.title}
                                </h1>
                                <h1 >
                                    {PortfolioConfig.desc}
                                </h1>
                            </div>
                        }
                        <Grid container spacing={9} className="">
                            {_.map(PortfolioConfig.primaryImages, pp => (
                                <>{!activeProject &&
                                    <Grid item md={11} lg={6} onClick={() => {
                                        // handleActiveProject(pp.id)
                                        history.push(`/Projects#${pp.id}`);

                                    }} className="mx-auto">
                                        <Fade up duration={1000} distance={'10%'}>
                                            <div className="titleWrapper">
                                                <h2 className="projectTitle">{pp.project}</h2>
                                                <img src={pp.src} className="" alt="..." />
                                            </div>
                                        </Fade>
                                    </Grid>
                                }
                                    {activeProject == pp.id &&
                                        <Grid item className="">
                                            <h2 className="project__header">{pp.project}</h2>
                                        </Grid>
                                    }
                                </>
                            ))}
                        </Grid>
                        {activeProject && <SLDGallery id={activeProject} primaryBlobs={primaryPhotos?.find(x => x.id == activeProject)?.blobs ?? []} thumbNailBlobs={thumbNailPhotos?.find(x => x.id == activeProject)?.blobs ?? []} />}
                        {activeProject &&
                            <Fade up duration={1000} distance={'10%'} delay={1000}>
                                <a href="/Projects#">
                                    <span className="navigation--text" onClick={() => handleActiveProject(null)} >
                                        <img src={RightArrow} alt={RightArrow} />  {PortfolioConfig.navigationText}
                                    </span>
                                </a>
                            </Fade>
                        }
                    </div>
                </Container>
            </div >

        </>
    );
}