import React, { useState } from 'react';
import '../site-components/Nav.scss'
import SLDLogo from './svgs/Primary_Landscape_Full.svg'
import { useLocation } from 'react-router';
import hamburgerIcon from '../site-components/svgs/Menu.svg'
import NavConfig from '../localization/NavConfig'
const Nav = ({ isSideNavOpen, setIsSideNavOpen }) => {
    const location = useLocation();
    const [isExpanded, setIsExpanded] = useState(null)

    return <nav className='nav'>
        <div className="nav__row nav__row--mobile">
            <figure className='SLDlogo-wrapper'><a href="/"><img draggable="false" className='SLDlogo' src={SLDLogo} alt="" /></a></figure>
            <ul className='nav__items'>
                {NavConfig.navList.map((x, i) => {
                    return <li key={i} className={'nav__item' + (location?.pathname.includes(x.navUrl) ? " active" : "")} onMouseOver={() => setIsExpanded(x.navName)} onMouseLeave={() => setIsExpanded(null)}>
                        <a href={x.navUrl}> {x.navName}</a>
                        {<div className={"dropdown--wrapper" + (isExpanded === x.navName ? " dropdown--wrapper-open" : '')}>
                            <div className="nav__sublist--wrapper">
                                {
                                    x.navSubList.map((y, yi) => {
                                        return <div key={yi} className='nav__subitem'>
                                            <a href={y.url}>{y.name}</a>
                                        </div>
                                    })
                                }
                            </div>
                        </div>}
                    </li>
                })}
            </ul>
            <figure className="hamburger--wrapper" onClick={() => setIsSideNavOpen(true)}>
                <img className='hamburger' src={hamburgerIcon} alt="SideNavButton" />
            </figure>
        </div>
        <>
            {isSideNavOpen && <div onClick={() => setIsSideNavOpen(false)} className="background" />}
            <div className={"side__nav" + (isSideNavOpen ? ' open' : ' close')}>
                <div className="side__nav__header--wrapper">
                    <figure onClick={() => setIsSideNavOpen(false)}> <img src={NavConfig.sideNavArrowIcon} /></figure>
                    <div className="side__nav__header">
                        {isSideNavOpen && <svg width="60" height="60" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id='svg__corners' d="M27.7819 9.54457C30.3338 10.1727 32.7496 11.4728 34.7262 13.4494C37.6208 16.344 39.0659 20.1741 39.0659 24.0043H48.0044L43.5769 22.814L40.634 21.9091C40.1903 18.3777 38.6179 14.9648 35.9166 12.2591C33.6149 9.95745 30.7731 8.48161 27.7775 7.81836V9.54457H27.7819Z" fill="#A8996E" />
                            <path id='svg__corners' d="M9.59739 20.3982C10.2431 17.8419 11.5827 15.4261 13.6164 13.4495C16.511 10.5549 20.3412 9.10981 24.1757 9.10981V0L22.8097 4.42753L22.0015 7.53733C18.4217 7.97657 14.9649 9.54466 12.2548 12.2592C9.94 14.5739 8.45976 17.4114 7.8009 20.3982H9.59739Z" fill="#4D4D4D" />
                            <path id='svg__corners' d="M20.5652 38.4641C18.0132 37.836 15.5974 36.5359 13.6208 34.5593C10.6428 31.6647 9.15373 27.8346 9.15373 24.0044H0L4.42753 25.1947L7.54612 26.1171C7.99414 29.6442 9.56222 33.0527 12.2591 35.7496C14.6047 38.0952 17.5081 39.5842 20.5652 40.2299V38.4641Z" fill="#A8996E" />
                            <path id='svg__corners' d="M38.6354 27.6062C38.0073 30.1626 36.7071 32.5784 34.7306 34.555C31.836 37.4495 28.0058 38.8946 24.1713 38.8946V48L25.366 43.4056L26.1566 40.4671C29.7408 40.0323 33.2064 38.4598 35.9209 35.7453C38.2357 33.4305 39.7159 30.593 40.3748 27.6062H38.6354Z" fill="#4D4D4D" />
                            <path d="M33.3909 14.7629C28.3704 9.74243 20.0995 9.74243 15.0746 14.7629C9.90481 19.7834 9.90481 28.0543 15.0746 33.0792C20.0951 38.0997 28.366 38.0997 33.3909 33.0792C38.4114 28.0587 38.4114 19.7878 33.3909 14.7629ZM21.0746 25.476C21.0746 25.8757 20.9341 26.2139 20.6486 26.4994C20.3631 26.7849 20.0249 26.9254 19.6252 26.9254H14.0205L15.5446 25.3969H19.5373V24.1626H15.4744C15.0746 24.1626 14.7364 24.0221 14.4509 23.741C14.1654 23.4599 14.0249 23.1173 14.0249 22.7132V21.3252C14.0249 20.9211 14.1654 20.5785 14.4509 20.2973C14.7364 20.0162 15.0746 19.8757 15.4744 19.8757H22.2869L20.7584 21.4042H15.5402V22.6385H19.6208C20.0205 22.6385 20.3587 22.779 20.6442 23.0645C20.9297 23.35 21.0703 23.6883 21.0703 24.088V25.476H21.0746ZM21.773 26.9035V21.391L23.2796 19.8845V25.3837H27.2064L25.6867 26.8991H21.7686L21.773 26.9035ZM34.041 24.7995C33.8433 25.23 33.5666 25.6033 33.2064 25.9152C32.8462 26.2315 32.4158 26.4774 31.9194 26.6619C31.4187 26.842 30.8741 26.9342 30.2767 26.9342H26.7101L27.2943 26.35L28.2386 25.4057H30.2503C30.6281 25.4057 30.9751 25.3574 31.2913 25.2651C31.6076 25.1685 31.8755 25.0367 32.0951 24.8566C32.3192 24.6809 32.4905 24.4701 32.6134 24.2329C32.7364 23.9913 32.8023 23.7234 32.8023 23.4291V23.4115C32.8023 23.1216 32.7408 22.8537 32.6134 22.6077C32.4861 22.3618 32.3148 22.1509 32.0951 21.9752C31.8711 21.7995 31.6032 21.6634 31.2913 21.5623C30.9751 21.4657 30.6281 21.4174 30.2503 21.4174H28.8096V24.7073H27.2943V19.8932H30.2767C30.8741 19.8932 31.4231 19.9811 31.9194 20.1612C32.4202 20.3413 32.8462 20.5872 33.2064 20.8991C33.5666 21.2153 33.8477 21.5843 34.041 22.0104C34.2386 22.4364 34.3353 22.8976 34.3353 23.394V23.4159C34.3353 23.9123 34.2386 24.3735 34.041 24.8039V24.7995Z" fill="#004B8D" />
                        </svg>}
                    </div>
                </div>
                {NavConfig.navList.map((x, i) => {
                    return <ul className='side__nav--wrapper' key={i}>
                        <li className='side__nav__subheader'><a href={x.navUrl}>{x.navName}</a></li>
                        {x.navSubList.map((y, index) => {
                            return <li key={index} className='side__nav__item'>
                                <a href={y.url}>{y.name}</a>
                            </li>
                        })}
                    </ul>

                })}
                <div className="side__nav__footer">
                    <div className="info">
                        <img src={NavConfig.addressSVG} alt="" />
                        <a href={NavConfig.addressURL} target='_blank'>{NavConfig.address}</a>
                    </div>
                    <div className="info">
                        <img src={NavConfig.phoneSVG} alt="" />
                        <a href={`tel:${NavConfig.phone}`}>{NavConfig.phone}</a>
                    </div>
                    <div className="info">
                        <img src={NavConfig.emailSVG} alt="" />
                        <a href={`mailto:${NavConfig.email}`}> {NavConfig.email}</a>
                    </div>
                </div>
            </div>
        </>

    </nav>
}


export default Nav;