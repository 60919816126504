import React from 'react';
import '../site-components/Footer.scss'
import FooterConfig from '../localization/FooterConfig'
import { useMediaQuery } from '@material-ui/core';
const Footer = () => {
    const matches = useMediaQuery('(max-width:1100px)');
    return <footer>
        {!matches && <>
            <div className="info">
                <img src={FooterConfig.locationSVG} alt="" />
                <a className='navigation--underline' target='_blank' rel="noopener noreferrer" href={FooterConfig.locationURL}> {FooterConfig.location}</a>
            </div>
            <div className="info">
                <img src={FooterConfig.phoneSVG} alt="" />
                <a className='navigation--underline' href={`tel:${FooterConfig.phoneNumber}`}>  {FooterConfig.phoneNumber}</a>
            </div>
            <div className="info">
                <img src={FooterConfig.emailSVG} alt="" />
                <a className='navigation--underline' href={`mailto:${FooterConfig.email}`}>{FooterConfig.email} </a>
            </div>
        </>}
        {matches && <span>Crafted with 🧃 by Civic DX </span>}
    </footer>
}


export default Footer;