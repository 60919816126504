import locationSVG from '../site-components/svgs/Location.svg'
import phoneSVG from '../site-components/svgs/Phone.svg'
import emailSVG from '../site-components/svgs/Email.svg'
import ContactImage from "../site-components/images/circle_of_excellence_CP-2020-small.png"
import TEAMImage from "../site-components/svgs/TEAM.svg"

const AboutConfig =
{
        bigHeader: "ABOUT SUPERIOR LAND DESIGNS",
        subHeader: "Leading a safety culture by projecting a better future.",

        locationAndContact: {
                title: "LOCATION & CONTACT",
                subTitle: "Home Office",
                image: ContactImage,
                address: '1815 Hembree Road, Suite 304, Alpharetta, GA 30009',
                description: "For any inquiries, questions or commendations, please contact us anytime.",
                phone: "(678) 644-1062",
                email: "info@superiorlanddesigns.com",
                locationURL: 'https://maps.app.goo.gl/5PJNWHc4qux9QdtD7',
                //Same icons from FooterConfig
                locationSVG: locationSVG,
                phoneSVG: phoneSVG,
                emailSVG: emailSVG
        },

        history: {
                title: "WHO WE ARE",
                description: [
                        "Superior Land Designs has been at the forefront of the pipeline industry since 2002, successfully executing complex projects in an ever-evolving landscape. With years of industry experience and a dedicated team of professionals, we have established ourselves as a trusted partner in the pipeline sector.",
                        "Throughout these years, we have worked on a wide range of projects from small-scale installations to large-scale pipeline networks. Our expertise spans across various industries, including oil and gas, water, and utilities. We have the knowledge and capabilities to handle projects of any scale and complexity, adapting to the unique requirements of each client and site.",
                        "The pipeline industry is constantly evolving, and we recognize the importance of staying up-to-date with the latest technologies, regulations, and best practices. Our team actively engages in ongoing training and professional development, ensuring that we remain at the cutting edge of the industry.",
                        "We take pride in our ability to tackle and overcome challenges, whether they are technical, logistical, or environmental in nature. Our experienced project managers provide comprehensive project planning and meticulous attention to detail, ensuring that every project is executed efficiently and with the highest level of quality.",
                        "In addition to our technical expertise, we understand the importance of effective communication and collaboration. We collaborate closely with our clients, stakeholders, and regulatory authorities to ensure that all project requirements, safety guidelines, and environmental standards are met.",
                        "Our track record speaks for itself, with a portfolio of successful pipeline projects completed on time and within budget. We are driven by a commitment to excellence and a passion for delivering exceptional results for our clients.",
                        "Since 2002, Superior Land Designs has been a trusted and reliable player in the pipeline industry. With our extensive experience, industry knowledge, and commitment to innovation, we are well-equipped to take on the challenges of the ever-evolving pipeline sector and deliver successful projects for our clients."
                ],
        },

        team: {
                title: "WHAT IS A TEAM?",
                description: ["One of the secrets to our success is the ability to grow and evolve as the industry evolves. We accomplish this through annual Operator Qualification, Safety and OSHA training in order to stay at the top of our field. All of our staff are vetted and trained to ensure the highest safety ratings in the industry. Our management TEAM visits the clients' job sites frequently to ensure that our field personnel feel and realize our level of commitment not only to our client, but to our employees as well."

                ],
                list: [
                        "T - Trusted",
                        "E - Experts in Our Industry",
                        "A - Accomplished, Able and willing to make a difference",
                        "M - Mastered, Motivated & Maintaining a higher Industry Standard",
                ],
                image: TEAMImage,
                imageAltText: "TEAM",
        },

        employeeResources: {
                title: "EMPLOYEE RESOURCES",
                description: "Current employees and contractors can upload site photos and access reference materials in the TEAM Resource Portal:",
                //placeholders (to be replaced with links to SharePoint site)
                navigationText: "SLD TEAM Resource Portal",
                navigationUrl: "https://netorg7673005-my.sharepoint.com/:f:/g/personal/pdover_superiorlanddesigns_com/EnKRv0m30t1JphXjJnnZa5wB2qgtGZRarnVddjoIWABrqw?e=AyffxX" //this will link to SharePoint when available
        },

        careers: {
                title: "CAREERS",
                //TODO: email in description needs to link out
                description: "To apply for a job with Superior Land Designs please send cover letter together with resume to",
                address: 'info@superiorlanddesigns.com'
        },
}

export default AboutConfig