import oshaLogo from "../site-components/svgs/oshaLogo.svg"
import nccerLogo from "../site-components/svgs/nccer.svg"

const SafetyCertificationConfig =
{
    bigHeader: "EMPLOYEE CERTIFICATION CATALOG",
    headerImages: [{ image: oshaLogo, alt: "OSHA logo" }, { image: nccerLogo, alt: 'nccer' }],
    safetyInfo: [
        {
            title: "OSHA 10 and 30 hour for Construction",
            description: [
                "Our OSHA 10 & 30 program provides our employees with basic through advanced training for safety and health hazards on the job. This employee provided course will give the employee knowledge, skills and the ability to mitigate potential hazards in the day to day activities. Safety is the highest priority for SLD. We expect our employees to operate above the bar in the industry.",
            ],
            navigationText: "View Details",
            navigationId: "osha",
            isDoubleList: true,
            footerDescription: "Hazards unique to an organization can be added to Elective Topics on a case-by-case basis.",
            details: [
                {
                    header: "OSHA 10 Construction (1.5 Days)",
                    leftHeader: "Required topics (7 hours)",
                    rightHeader: "Elective topics (3 hours)",
                    leftList: [
                        "Intro to OSHA (2 hours)",
                        "OSHA Focus Four (4 hours)",
                        ["Falls", "Electrocution", "Struck By", "Caught In or Between",],
                        "Personal Protective Equipment (30 min.)",
                        "Health Hazards in Construction (30 min.)"
                    ],
                    rightList: [
                        "Cranes, Derricks, Hoists, Elevators, and Conveyors",
                        "Excavations",
                        "Materials Handling, Storage, Use and Disposal",
                        "Scaffolds",
                        "Stairways and Ladders",
                        "Tools — Hand and Power",
                    ]
                },
                {
                    header: "OSHA 30 Construction (4 Days)",
                    leftHeader: "Required topics (15 hours)",
                    rightHeader: "Elective topics (15 hours)",
                    leftList: [
                        "Intro to OSHA (2 hours)",
                        "Managing Safety and Health (2 hours)",
                        "OSHA Focus Four (4 hours)",
                        ["Falls", "Electrocution", "Struck By", "Caught In or Between",],
                        "Personal Protective Equipment (2 hours)",
                        "Health Hazards in Construction (2 hours)",
                        "Stairways and Ladders (1 hour)"
                    ],
                    rightList: [
                        "Confined Space Entry",
                        "Cranes, Derricks, Hoists, Elevators, and Conveyors",
                        "Excavations",
                        "Fire Protection and Prevention",
                        "Materials Handling, Storage, Use and Disposal",
                        "Motor Vehicle and Mechanized Equipment",
                        "Safety and Health Programs",
                        "Scaffolds",
                        "Stairways and Ladders",
                        "Tools — Hand and Power",
                        "Welding and Cutting",
                    ],
                }
            ],
        },
        {
            title: "Competent Person Training For Excavations",
            description: "Excavation and trenching are among the most hazardous construction operations. In fact, according to the Bureau of Labor Statistics, excavation and trench-related fatalities in 2016 were nearly double the average of the previous five years. To that end, Superior Land Designs has developed a comprehensive 1-day Competent Persons Training for Excavations. Individuals who successfully complete this course will be presented with a wallet card and Competent Person hard hat sticker.",
            navigationText: "View Details",
            navigationId: "excavation",
            isDoubleList: false,
            footerDescription: "OSHA defines a competent person as an individual who is capable of identifying existing and predictable hazards or working conditions that are hazardous, unsanitary, or dangerous to employees and who is authorized to take prompt corrective measures to eliminate or control these hazards and conditions. Our course will equip our employees with the tools and knowledge to identify these hazards, mitigate them, and make sure everyone goes home safe at the end of the day.",
            details: [
                {
                    header: "Topics Covered:",
                    list: [
                        "Soil typing and analysis",
                        "Correct sloping angles",
                        "Different types of protection systems",
                        "Hazard recognition",
                    ],
                }
            ],
        },
        {
            title: "NCCER Operator Qualification Training and Evaluations",
            description: "As an Accredited Assessment Center, Superior Land Designs is proud to offer training and assessments trough NCCER in any one of 127 covered tasks. We have multiple Master Trainers on staff. Testing can be done online or on paper if need be. Obtaining an OQ is a two-part process. First candidates must pass the written evaluation, and then an in field performance evaluation. Once complete, the results are sent to NCCER and then forwarded to ISN.",
            navigationText: "View Details",
            navigationId: "operator",
            isDoubleList: false,
            details: [
                {
                    header: "For more information on NCCER's Pipeline Program, please visit:",
                    list: [
                        "https://www.nccer.org/programs-crafts/programs/pipeline-program/",
                        "https://www.nccer.org/programs-crafts/programs/pipeline-program/covered-tasks-reference-table/"
                    ],
                }
            ],
        },
        {
            title: "HAZWOPER 8 Hour Refresher",
            description: "Our 8-hour refresher course was developed to meet the requirements outlined in OSHA 29 CFR 1910.120 for 8 (eight) hours of annual refresher training for pipeline workers. Employers seeking compliance with OSHA's Hazardous Waste Operations and Emergency Response Standard should provide this refresher course for their employees who may be exposed to potentially dangerous substances or may be asked to assist in an emergency response. Upon successful completion, participants will be given a wallet card.",
            navigationText: "View Details",
            navigationId: "hazwoper",
            isDoubleList: false,
            footerDescription: "This course ends with a simulated emergency response so our employees can put what they have learned into action!",
            details: [
                {
                    header: "Topics Covered:",
                    list: [
                        "Regulatory Overview",
                        "Training Requirements",
                        "Hazard Communication",
                        "Personal Protective Equipment",
                        "Site Characterization",
                        "Reconnaissance",
                        "Containment",
                        "Decontamination Methods",
                    ],
                }
            ],
        },
    ],
    

}

export default SafetyCertificationConfig