import React from 'react';
import HomepageConfig from 'localization/HomepageConfig';
import './Homepage.scss'
import RightArrow from '../site-components/svgs/RightArrow.svg'
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Fade } from 'react-reveal';
import { Card, CardContent } from '@material-ui/core';
function SliderArrowNext(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
}

function SliderArrowPrev(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
}

export default function Homepage() {
  const marketingTestimonials1 = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
    responsive: [
      {
        breakpoint: 1100,
        settings: { slidesToShow: 2, slidesToScroll: 2 }
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: 1, slidesToScroll: 1 }
      }
    ]
  };
  return <div className="container">
    <h1>
      {HomepageConfig.homepageHeader}
    </h1>
    <section id="homepage__landing">
      <figure className='homepage__landing--img--wrapper'>
        <img className="homepage__landing--img" src={HomepageConfig.homePageImage} alt='' />
      </figure>
      <div className="homepage__landing--desc-wrapper">
        <h2>{HomepageConfig.homePageImageTitle}</h2>
        <p className="homepage__landing--desc-top">
          {HomepageConfig.homePageImage1Desc}
        </p>
        <p className="homepage__landing--desc-bottom" dangerouslySetInnerHTML={{ __html: HomepageConfig.homePageImage2Desc }}>

        </p>
        <a href={HomepageConfig.homePageImageNavigationRedirect}>
          <span className="homepage__landing--navigator">
            {HomepageConfig.homePageImageNavigateText} <img src={RightArrow} alt="" />
          </span>
        </a>
      </div>
    </section>
    <div className="logos">
      {HomepageConfig.firstLogos.map((x, i) => {
        return <figure key={i} className='logo--wrapper'>
          <img src={x} className='logo' alt={x} />
        </figure>
      })}
    </div>
    <h1 className="logos__text">
      {HomepageConfig.firstLogosText}
    </h1>
    <section id="safety">
      <div className="left-col">
        <h2 className="title">
          {HomepageConfig.safetyTitle}
        </h2>
        <p className='desc'>
          {HomepageConfig.safetyDesc}
        </p>
        <a href={HomepageConfig.safetyNavigateUrl}>
          <span className='navigator'>{HomepageConfig.safetyNavigateText} <img src={RightArrow} alt="" /></span>
        </a>
      </div>
      <figure className="right-col">
        <img src={HomepageConfig.safetyImage} alt="safety img" />
      </figure>
    </section>
    <div className="logos safety__logos">
      {HomepageConfig.safetyLogos.map((x, i) => {
        return <figure key={i} className='logo--wrapper'>
          <img src={x} className='logo' alt={x} />
        </figure>
      })}
    </div>
    <h1 className="logos__text">
      {HomepageConfig.safetyLogosText}
    </h1>
    <section id="about">
      <figure className="right-col">
        <img src={HomepageConfig.aboutImage} alt="safety img" />
      </figure>
      <div className="left-col">
        <h2 className="title">
          {HomepageConfig.aboutTitle}
        </h2>
        <p className='desc'>
          {HomepageConfig.aboutDesc}
        </p>
        <a href={HomepageConfig.aboutNavigateUrl}>
          <span className='navigator'>{HomepageConfig.aboutNavigateText} <img src={RightArrow} alt="" /></span>
        </a>
      </div>
    </section>
    <section id='testimonial'>
      <Slider {...marketingTestimonials1} className="slider-arrows-outside mb-5 slider-arrows slider-dots-outside slick-mobile testimonials">
        {
          HomepageConfig.slider.map((x, i) =>
            <div key={i}>
              <Fade up distance="1rem" delay={250}>
                <Card className="m-2 grab-pointer">
                  <CardContent>
                    <div className="align-box-row align-items-start">
                      <div className="pl-3">
                        <FontAwesomeIcon
                          icon={faQuoteRight}
                          className="blue-text"
                        />
                        <blockquote className="center">
                          {x}
                        </blockquote>

                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Fade>
            </div>)
        }
      </Slider>
    </section>
    <h1 className="contact--text">
      {HomepageConfig.contactUsText}
    </h1>
  </div>
}
