

import rightArrow from '../site-components/svgs/RightArrowFillBlack.svg'
import locationSVG from '../site-components/svgs/blackLocation.svg'
import phoneSVG from '../site-components/svgs/blackPhone.svg'
import emailSVG from '../site-components/svgs/blackEmail.svg'
import logoSVG from '../site-components/svgs/Logo.svg'
const NavConfig = {
    navList: [
        {
            navName: 'Pipeline Services',
            navUrl: '/Services',
            navSubList: [
                {
                    name: "Our Expertise",
                    url: '/Services#expertise'
                },
                {
                    name: "Infrastructure Services",
                    url: '/Services-Inspections',
                },
                {
                    name: "Design Engineering",
                    url: '/Services-Engineering',
                },
                {
                    name: "Professional Survey",
                    url: 'Services-Surveying',
                },
                {
                    name: "Project Examples",
                    url: '/Projects',
                }
            ]
        },
        {
            navName: 'Safety Training',
            navUrl: '/Safety-Overview',
            navSubList: [
                {
                    name: "Our Committment",
                    url: '/Safety-Overview#commitment'
                },
                {
                    name: "Training Services",
                    url: '/Safety-Overview#training-services',
                },
                {
                    name: "Available Certifications",
                    url: '/Safety-Certifications',
                },
            ]
        },
        {
            navName: 'Company',
            navUrl: '/About',
            navSubList: [
                {
                    name: "Location & Contact",
                    url: '/About#address',
                },
                {
                    name: "Who We Are",
                    url: '/About#history'
                },
                {
                    name: "What is a TEAM?",
                    url: '/About#team'
                },
                {
                    name: "Employee Resources",
                    url: '/About#resource',
                },
                {
                    name: "Careers at SLD",
                    url: '/About#careers',
                },
            ]
        }
    ],
    sideNavArrowIcon: rightArrow,
    sideNavHeader: logoSVG,
    addressSVG: locationSVG,
    phoneSVG: phoneSVG,
    emailSVG: emailSVG,
    addressURL: "https://maps.app.goo.gl/5PJNWHc4qux9QdtD7",
    address: '1815 Hembree Road, Suite 304, Alpharetta, GA 30009',
    phone: '(678) 644-1062',
    email: 'info@superiorlanddesigns.com'
}


export default NavConfig