import locationSVG from '../site-components/svgs/Location.svg'
import phoneSVG from '../site-components/svgs/Phone.svg'
import emailSVG from '../site-components/svgs/Email.svg'

const FooterConfig =
{
    locationSVG: locationSVG,
    phoneSVG: phoneSVG,
    emailSVG: emailSVG,
    locationURL: "https://www.google.com/maps?q=P.O.+Box+5864,+Alpharetta,+GA+30023",
    location: "P.O. Box 5864 Alpharetta, GA 30023",
    phoneNumber: "(678) 644-1062",
    email: "info@superiorlanddesigns.com"
}


export default FooterConfig