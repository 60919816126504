import React from 'react';
import ServicesDetailConfig from '../../localization/ServicesSurveyingConfig';
import '../Services.scss'
import RightArrow from '../../site-components/svgs/RightArrow.svg'
import { useMediaQuery } from '@material-ui/core';

//TODO: List view needs CSS revised, indent
//TODO: align nav to left side (add padding below contactHeader)

export default function ServicesSurveying() {
    const matches = useMediaQuery('(max-width:480px)');

    return <div className="container">
        <div className="header--wrapper header--wrapper-mobile">
            <h1 className="header--big">
                {ServicesDetailConfig.topHeader}
            </h1>
            <h1>{ServicesDetailConfig.subHeader}</h1>
        </div>
        <div className="row">
            <p>{ServicesDetailConfig.description[0]}</p>
            <p>{ServicesDetailConfig.description[1]}</p>
            <p>{ServicesDetailConfig.description[2]}{ServicesDetailConfig.description[3]}</p>
        </div>
        <div className="row">
            {!matches ? <div className="service--wrapper">
                <div className="left--col">
                    <h2 className="title">{ServicesDetailConfig.services.header}</h2>
                    <ul className='list--wrapper'>{ServicesDetailConfig.services.list.map((z, i) => {
                        if (Array.isArray(z)) {
                            return <ul>{z.map((z, zi) => <li>{z}</li>)} </ul>
                        } else return <li>{z}</li>
                    })}
                    </ul>
                </div>
                <figure className="right--col">
                    <img className='image' src={ServicesDetailConfig.services.image} alt={ServicesDetailConfig.services.imageAltText} />
                </figure>
            </div>
                :
                <div className="service--wrapper">
                    <h2 className="title">{ServicesDetailConfig.services.header}</h2>
                    <figure >
                        <img className='image' src={ServicesDetailConfig.services.image} alt={ServicesDetailConfig.services.imageAltText} />
                    </figure>
                    <ul className='list--wrapper'>{ServicesDetailConfig.services.list.map((z, i) => {
                        if (Array.isArray(z)) {
                            return <ul>{z.map((z, zi) => <li>{z}</li>)} </ul>
                        } else return <li>{z}</li>
                    })}
                    </ul>
                </div>
            }
        </div>
        <div className="contact--text-margin">
            <h1>
                {ServicesDetailConfig.contactHeader}
            </h1>
        </div>
        <div className="navigation--wrapper-service">
            <a href={ServicesDetailConfig.backNavigationURL}>
                <span className="navigation--text navigation--text-back" >
                    <img src={RightArrow} alt={RightArrow} /> {ServicesDetailConfig.backNavigationText}
                </span>
            </a>
        </div>

    </div>
}

