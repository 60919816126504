import React from 'react';
import SafetyConfig from 'localization/SafetyConfig';

import RightArrow from '../site-components/svgs/RightArrow.svg'
import './Safety.scss'
import { useMediaQuery } from '@material-ui/core';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faQuoteRight, faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Fade } from 'react-reveal';
import { Card, CardContent } from '@material-ui/core';

function SliderArrowNext(props) {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <FontAwesomeIcon icon={faChevronRight} />
        </div>
    );
}

function SliderArrowPrev(props) {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <FontAwesomeIcon icon={faChevronRight} />
        </div>
    );
}
export default function Safety() {
    const matches = useMediaQuery('(max-width:480px)');
    const sliderSettings = {
        dots: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SliderArrowNext />,
        prevArrow: <SliderArrowPrev />,
        responsive: [
            {
                breakpoint: 1100,
                settings: { slidesToShow: 2, slidesToScroll: 2 }
            },
            {
                breakpoint: 900,
                settings: { slidesToShow: 1, slidesToScroll: 1 }
            }
        ]
    };
    return <div className="container safety__overview_container" >
        <div className="header--wrapper header--wrapper-mobile">
            <h1 className="header--big">
                {SafetyConfig.bigHeader}
            </h1>
            <h1>{SafetyConfig.subHeader}</h1>
        </div>
        {
            SafetyConfig.sections.map((x, i) => {
                return <div className="row" id={x.id}>
                    {
                        x.image ?
                            !matches ?
                                <>
                                    {x.title === "AVAILABLE CERTIFICATIONS" && <section id='testimonial'>
                                        <Slider {...sliderSettings} className="slider-arrows-outside mb-5 slider-arrows slider-dots-outside slick-mobile testimonials">
                                            {
                                                x.slider.map((x, i) =>
                                                    <div key={i}>
                                                        <Fade up distance="1rem" delay={250}>
                                                            <Card className="m-2 grab-pointer">
                                                                <CardContent>
                                                                    <div className="align-box-row align-items-start">
                                                                        <div className="pl-3">
                                                                            <FontAwesomeIcon
                                                                                icon={faQuoteRight}
                                                                                className="blue-text"
                                                                            />
                                                                            <blockquote className="center">
                                                                                {x}
                                                                            </blockquote>

                                                                        </div>
                                                                    </div>
                                                                </CardContent>
                                                            </Card>
                                                        </Fade>
                                                    </div>)
                                            }
                                        </Slider>
                                    </section>}
                                    <div className="safety__overview--wrapper">
                                        <div className="left--col">
                                            <h2>{x.title}</h2>
                                            <>
                                                {
                                                    x.description && x.description?.map((y, yi) => {
                                                        return <p className='desc'>{y}</p>
                                                    })
                                                }
                                                {x.list && <ul className='safety__overview--list'>
                                                    {
                                                        x.list?.map((y, yi) => {
                                                            return <li>
                                                                <a href="">{y.navigationText}</a>
                                                            </li>
                                                        })
                                                    }
                                                </ul>}
                                            </>
                                            {
                                                x.navigationURL && x.navigationURL && <a href={x.navigationURL}>
                                                    <span className="navigation--text">
                                                        {x.navigationText} <img src={RightArrow} alt={RightArrow} />
                                                    </span>
                                                </a>
                                            }
                                        </div>
                                        <figure className="right--col">
                                            <img className='image' src={x.image} alt={x.imageAltText} />
                                        </figure>
                                    </div>
                                </>

                                :
                                <>
                                    {
                                        x.title === "AVAILABLE CERTIFICATIONS" &&
                                        <section id='testimonial'>
                                            <Slider {...sliderSettings} className="slider-arrows-outside mb-5 slider-arrows slider-dots-outside slick-mobile testimonials">
                                                {
                                                    x.slider.map((x, i) =>
                                                        <div key={i}>
                                                            <Fade up distance="1rem" delay={250}>
                                                                <Card className="m-2 grab-pointer">
                                                                    <CardContent>
                                                                        <div className="align-box-row align-items-start">
                                                                            <div className="pl-3">
                                                                                <FontAwesomeIcon
                                                                                    icon={faQuoteRight}
                                                                                    className="blue-text"
                                                                                />
                                                                                <blockquote className="center">
                                                                                    {x}
                                                                                </blockquote>

                                                                            </div>
                                                                        </div>
                                                                    </CardContent>
                                                                </Card>
                                                            </Fade>
                                                        </div>)
                                                }
                                            </Slider>
                                        </section>

                                    }
                                    <div className="safety__overview--wrapper">
                                        <h2>{x.title}</h2>
                                        <figure className={x.imageAltText}>
                                            <img className='image' src={x.image} alt={x.imageAltText} />
                                        </figure>
                                        {
                                            x.description && x.description?.map((y, yi) => {
                                                return <p className='desc'>{y}</p>
                                            })
                                        }
                                        {x.list && <ul className='safety__overview--list'>
                                            {
                                                x.list?.map((y, yi) => {
                                                    return <li>
                                                        <a href="">{y.navigationText}</a>
                                                    </li>
                                                })
                                            }
                                        </ul>}
                                        {
                                            x.navigationURL && <a href={x.navigationURL}>
                                                <span className="navigation--text">
                                                    {x.navigationText} <img src={RightArrow} alt={RightArrow} />
                                                </span>
                                            </a>
                                        }


                                    </div>
                                </>

                            :
                            <>
                                <h2>{x.title}</h2>
                                {
                                    x.description && x.description?.map((y, yi) => {
                                        return <p>{y}</p>
                                    })
                                }
                                {x.list && <ul>
                                    {
                                        x.list?.map((y, yi) => {
                                            return <li>
                                                <a href="">{y.navigationText}</a>
                                            </li>
                                        })
                                    }
                                </ul>}
                            </>
                    }
                </div>
            })
        }
    </div >
}
