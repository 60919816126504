//import homePageFirstImage from '../site-components/images/home_page_photo-tanks.jpg' //submitted photo, crops poorly
import homePageFirstImage from '../site-components/images/projects/project-covers/092.JPG'
import safetyImage from '../site-components/images/proj3-creekcrossing.jpg'
import aboutImage from '../site-components/images/proj2-tankfarm.jpg'
import buckeyeLogo from '../site-components/svgs/buckeye-partners.svg'
import kinderMorganLogo from '../site-components/svgs/kinder-morgan.svg'
import att from '../site-components/svgs/att.svg'
import faa from '../site-components/svgs/faa.svg'
import colpipeLineLogo from '../site-components/svgs/colonial-pipeline.svg'
import southCompanyLogo from '../site-components/svgs/southern-company.svg'
import osha from '../site-components/svgs/osha.svg'
import nccer from '../site-components/svgs/nccer.svg'
import energyLogo from '../site-components/svgs/energy_worldnet.svg'

const HomepageConfig =
{
    homepageHeader: 'Leading Safety Culture By Projecting a Better Future',
    homePageImage: homePageFirstImage,
    homePageImageTitle: 'EXPERTISE IN PIPELINE',
    homePageImage1Desc: "Superior Land Designs provides Construction/Integrity Inspection and Quality Control Personnel to the Petroleum and Natural Gas Industry along with Surveying, Pipeline Route Selection and Engineering expertise.",
    homePageImage2Desc: "Our Management <a class='team-link' href='/About#history'>TEAM</a> consists of experienced professionals with 335 years of combined field and administrative experience in the pipeline industry. This <a class='team-link' href='/About#history'>TEAM</a>, known in the industry for best-in-class services, embraces safety and safety culture as a core tenement of our corporate philosophy. However, we recognize that “what got us here will not get us there”. To that effect, we are constantly exploring new best practices and technology that will lead to a safer, more efficient, and better future.",
    homePageImageNavigateText: "Explore our Services",
    homePageImageNavigationRedirect: "/Services",

    firstLogos: [buckeyeLogo, kinderMorganLogo, att, faa, colpipeLineLogo, southCompanyLogo],
    firstLogosText: "25 Years of Experience Across the Public & Private Sector",

    safetyImage: safetyImage,
    safetyDesc: 'Superior Land Designs is committed to the continuous improvement of environmental, health and safety performance to help achieve the greatest benefit for all our clients. To achieve these goals, we employ a full time Safety and Training Manager to assist our employees in attaining knowledge, tools, training, and certifications in many pipeline disciplines as well as perform frequent job site inspections.',
    safetyTitle: "COMMITMENT TO SAFETY",
    safetyNavigateText: "Explore our Training Programs",
    safetyNavigateUrl: "/Safety-Overview",
    safetyLogos: [osha, nccer, energyLogo],
    safetyLogosText: "Partner with Industry Leaders in Operational Safety",

    aboutImage: aboutImage,
    aboutDesc: 'Superior Land Designs was formed in 2003 as a survey firm. In 2008, we began focusing on the specific needs of the pipeline industry. SLD has worked on many different projects including pipeline relocations, tank dike improvements, as well as perform annual hydrographic inspections for navigable waterways. We utilize and invest in the latest survey technology to provide our clients with the best and most accurate data. Our licensed surveyors have the background and knowledge to make your project a success.',
    aboutTitle: "A 21ST CENTURY COMPANY",
    aboutNavigateUrl: "/About",
    aboutNavigateText: "Learn more about Superior Land Designs",
    contactUsText: "Contact us today to learn how we can help you achieve a reliable and efficient outcome for your pipeline project.",

    slider: [
        "Mike was a key contributor in the construction phase of the Donelson Pike Relocation. Mike initiated budgetary estimate discussions with contractors to help develop the scope of the abandonment phase of the project.",
        "During pre-construction, geophysical surveys were conducted. Mike exercised Stop Work Authority when he realized the contractor would be drilling holes through asphalt without a 811 ticket.",
        "Patrick managed and executed the project very well without interruptions.",
        "Dustin does an outstanding job managing multiple projects.",
        "Ben does an outstanding job around the tank farm.",
        "Excellent drawing work completed. Willing to work and adjust any comments as needed for drawing + recommendation for best methods to survey.",
        "Shane continues to go above and beyond on any project he is assigned.",
        "Good work and good drawing product at completion on the Tank Line Project.",
        "Bill did an excellent work ensuring at Atlanta Junction helping with several projects that involved extensive excavation inside the facility. He communicates well with the pipeline contractor and colonial personnel.",
        "Aaron is a Super good and knowledgeable inspector.",
        "Jon-Paul is very detailed orientated and understands and enforces Colonial safety and quality expectations.",
        "Chaz is very good inspector. He does not cut corners and will not let others cut corners. He is very willing to go where needed. He was willing to respond to ER and did a great job.",
        "I observed Patrick using the required PPE, including tick spray. Patrick worked safely and took breaks in the shade to avoid overheating. Survey was completed accurately and he insured that we knew the location of Line 19 to be just inside the 50ft easement.",
        "Aaron is doing an excellent job on this project being engages with the pipeline contractor and Colonial project personnel to help improve work plans and identify near misses.",
        "SLD met expectations overall for this work. They executed locating & collecting all tank line elevation data in a timely & accurate manner.",
        "Jason Boudreaux has been a solid and quality ROW Inspector for the NED ROW department.",
        "Superior Land Designs executed the survey work in a safe and timely manner. The final drawing was received at an earlier than anticipated time and overall work quality exceeded expectations.",
        "Roy did an Excellent job overseeing work and communicating with Colonial ROW team.",
        "Jay Oversaw Airport expansion work on ROW. Excellent job coordinating and communicating work with multiple entities.",
        "Survey Crew Exceeded expectations on the Tank Line Survey Project.",
        "Roy has worked safely and done an exceptional job in dealing with property owners and ensuring the side trimming crews are working safely and delivering a quality product.",
        "Justin continues doing a good job on this project. He is reporting good catches and near misses. The job involves significant excavation in the facility and no incidents have occurred to date.",
        "Mike did an excellent job during this project. Excellent communication and documentation.",
        "Justin continues doing a good job documenting the project progress, reporting near misses and good catches and ensuring the crew follows Colonial's SAM	",
        "Roy epitomizes personal safety and leadership. He demonstrates in-depth knowledge and enforces strict compliance with our procedures. His communication with stakeholders is excellent, and he efficiently notifies personnel on any issues that arise.",
        "Roy ensures that our contractors use equipment safely and maintains in in great working order.",
        "Aaron excels in inspecting 3rd party contractors and checking one-call tickets. He has an extensive knowledge base and prioritizes personal safety. Aaron ensures that everyone adheres to safety protocols and exercises his SWA when necessary. He understands the industry deeply and strictly follows Colonial standards and procedures.",
        "Aaron communicates effectively with stakeholders, and promptly notifies local inspectors or me of any concerns or issues.",
        "Jay has done a great job for us inspecting the ROW in South GA. He excels in communicating with stakeholders and addressing any issues that may arise on the right-of-way. He has a thorough industry knowledge and works exceptionally well with 3rd party excavators, ensuring they follow our standards and procedures.",
        "SLD has done a quality job for us with this project. They are on schedule and currently under budget. They have done everything that we have requested safely and efficiently.",
        "Providing Best in Class Services to the Petroleum & Natural Gas Industry ",
        "The Superior Advantage - One Stop. One Company",
        "Safety is a small investment for a rich future.",
        "A 21st Century Company",
        "Helping Fuel the American needs with Competent and Qualified Safety Inspection",
    ]
}


export default HomepageConfig