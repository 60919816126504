import React, { useState, useCallback, useEffect } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Fade from 'react-reveal/Fade';
import _ from "lodash";
import PortfolioConfig from 'localization/PortfolioConfig';

export default function SLDGallery({ id, primaryBlobs, thumbNailBlobs }) {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    return (
        <div className="galleryWrapper">
            <Fade up duration={1000} distance={'10%'}>
                <Gallery photos={thumbNailBlobs} onClick={openLightbox} />
            </Fade>
            <ModalGateway>
                {(viewerIsOpen && primaryBlobs.length > 0) ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={primaryBlobs}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    );
}

