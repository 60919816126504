import React from 'react';
import AboutConfig from 'localization/AboutConfig';
import './About.scss'
import RightArrow from '../site-components/svgs/RightArrow.svg'
import { useMediaQuery } from '@material-ui/core';

//TODO: TEAM CSS needs styling fixes (uses references from Services.csss)
export default function About() {
    const matches = useMediaQuery('(max-width:480px)');
    return <div className="container">
        <div className="header--wrapper header--wrapper-mobile">
            <h1 className="header--big">
                {AboutConfig.bigHeader}
            </h1>
            <h1>{AboutConfig.subHeader}</h1>
        </div>
        <div className="row" id='address'>
            <h2 className="row__header">{AboutConfig.locationAndContact.title}</h2>
            <h4 className='row__subheader'>{AboutConfig.locationAndContact.subTitle}</h4>
            <div className="about--wrapper">
                <div className="left--col">
                    <div className="info">
                        <img src={AboutConfig.locationAndContact.locationSVG} alt="" />
                        <a className='navigation--underline' rel="noopener noreferrer" target='_blank' href={AboutConfig.locationAndContact.locationURL}> {AboutConfig.locationAndContact.address}</a>
                    </div>
                    <p>
                        {AboutConfig.locationAndContact.description}
                    </p>
                    <div className="info">
                        <img src={AboutConfig.locationAndContact.phoneSVG} alt="" />
                        <a className='navigation--underline' href={`tel:${AboutConfig.locationAndContact.phone}`}>{AboutConfig.locationAndContact.phone}</a>
                    </div>
                    <div className="info">
                        <img src={AboutConfig.locationAndContact.emailSVG} alt="" />
                        <a className='navigation--underline' href={`mailto:${AboutConfig.locationAndContact.email}`}> {AboutConfig.locationAndContact.email}</a>
                    </div>

                </div>
                {!matches && <figure className="right--col">
                    <img src={AboutConfig.locationAndContact.image} alt="" />
                </figure>}
            </div>
        </div>
        <div className="row" id='history'>
            <h2 className="row__header">{AboutConfig.history.title}</h2>
            {AboutConfig.history.description.map(x => {
                return <p>{x}</p>
            })}
        </div>
        <div className="row" id="team">
            <h2 className="row__header">{AboutConfig.team.title}</h2>
            <p>{AboutConfig.team.description[0]}</p>
            {!matches ? <div className="service--wrapper ">
                <div className="left--col">
                    <ul className='list--wrapper'>{AboutConfig.team.list.map((z, i) => {
                        if (Array.isArray(z)) {
                            return <ul>{z.map((z, zi) => <li>{z}</li>)} </ul>
                        } else return <li>{z}</li>
                    })}
                    </ul>
                </div>
                <figure className="right--col">
                    <img className='image' src={AboutConfig.team.image} alt={AboutConfig.team.imageAltText} />
                </figure>
            </div>
                :
                <div className="service--wrapper">
                    <figure className="right--col teamImageWrapper">
                        <img className='image teamImage' src={AboutConfig.team.image} alt={AboutConfig.team.imageAltText} />
                    </figure>
                    <ul className='list--wrapper'>{AboutConfig.team.list.map((z, i) => {
                        if (Array.isArray(z)) {
                            return <ul>{z.map((z, zi) => <li>{z}</li>)} </ul>
                        } else return <li>{z}</li>
                    })}
                    </ul>
                </div>
            }
        </div>

        <div className="row" id='resource'>
            <h2 className="row__header">{AboutConfig.employeeResources.title}</h2>
            <p> {AboutConfig.employeeResources.description}</p>
            <a href={AboutConfig.employeeResources.navigationUrl} target='_blank'>
                <span className="navigation--text" >
                    {AboutConfig.employeeResources.navigationText} <img src={RightArrow} alt="" />
                </span>
            </a>
        </div>
        <div className="row" id='careers'>
            <h2 className="row__header">{AboutConfig.careers.title}</h2>
            <p> {AboutConfig.careers.description} <a className='navigation--underline' href={`mailto:${AboutConfig.careers.address}`}>{AboutConfig.careers.address}</a>.</p>
        </div>
    </div>
}
