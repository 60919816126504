import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Nav from 'site-components/Nav';
import Homepage from 'pages/Homepage';
import './Base.scss'
import Footer from 'site-components/Footer';
import Services from 'pages/Services';
import ServicesEngineering from 'pages/Services/ServicesEngineering';
import ServicesInspections from 'pages/Services/ServicesInspections';
import ServicesSurveying from 'pages/Services/ServicesSurveying';
import SafetyCertification from 'pages/SafetyCertification';
import { useState } from 'react';
import About from 'pages/About';
import Safety from 'pages/Safety';
import Projects from 'pages/Projects';

const App = () => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false)
  {
    return (
      <div className={'App' + (isSideNavOpen ? ' side__nav--open' : '')}>
        <BrowserRouter basename="/">
          <Nav setIsSideNavOpen={setIsSideNavOpen} isSideNavOpen={isSideNavOpen} />
          <Switch>
            <Route exact path="/">
              <Homepage />
            </Route>
            <Route path="/Services">
              <Services />
            </Route>
            <Route path="/Services-Inspections">
              <ServicesInspections />
            </Route>
            <Route path="/Services-Engineering">
              <ServicesEngineering />
            </Route>
            <Route path="/Services-Surveying">
              <ServicesSurveying />
            </Route>
            <Route path="/Safety-Overview">
              <Safety />
            </Route>
            <Route path="/Safety-Certifications">
              <SafetyCertification />
            </Route>
            <Route path="/About">
              <About />
            </Route>
            <Route path="/Projects">
              <Projects />
            </Route>
          </Switch>
          <Footer />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
