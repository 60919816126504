import React, { useState } from 'react';
import SafetyCertificationConfig from 'localization/SafetyCertificationConfig';
import plusIcon from '../site-components/svgs/plus.svg'
import minusIcon from '../site-components/svgs/minus.svg'
import './Safety.scss'

export default function SafetyCertification() {
    const [expandDetails, setExpandDetails] = useState(null)
    return <div className="container">
        <div className="header--wrapper">
            <h1 className="header--big">
                {SafetyCertificationConfig.bigHeader}
                <div className="images">
                    {SafetyCertificationConfig.headerImages.map((x, i) => {
                        return <figure className='image-wrapper' key={i}>
                            <img src={x.image} alt={x.alt} />
                        </figure>
                    })}
                </div>
            </h1>
        </div>
        {
            SafetyCertificationConfig.safetyInfo.map((x, i) => {
                return <div key={i} className="row safety__row">
                    <h2>{x.title}</h2>
                    <p>{x.description}</p>
                    <div className="accordian--wrapper"  >
                        <div className="accordian" onClick={() => {
                            if (expandDetails === x.navigationId) {
                                setExpandDetails(null)
                            } else setExpandDetails(x.navigationId)
                        }}>
                            {x.navigationText}
                            <img className='accordian__icon' src={expandDetails === x.navigationId ? minusIcon : plusIcon} alt="" />
                        </div>
                        <div className={"accordian__content--wrapper" + (expandDetails === x.navigationId ? " accordian__content--wrapper-open" : " accordian__content--wrapper-closed")}>
                            <div className="accordian__content">
                                <div className={"safety__info--row" + (!x.isDoubleList ? " safety__info--row-one-col" : " ")}>
                                    {
                                        x?.details.map((y, yi) => {
                                            return <div className="safety__info--wrapper" key={yi}>
                                                <h3 className='safety__details--header'>{y.header}</h3>
                                                {
                                                    x.isDoubleList ? <>
                                                        <div className="safety__info--col">
                                                            <h4>{y.leftHeader}</h4>
                                                            <ul>{y.leftList.map((z, i) => {
                                                                if (Array.isArray(z)) {
                                                                    return <ul className='sublist'>{z.map((z, zi) => <li>{z}</li>)} </ul>
                                                                } else return <li>{z}</li>
                                                            })}</ul>
                                                        </div>
                                                        <div className="safety__info--col">
                                                            <h4>{y.rightHeader}</h4>
                                                            <ul>{y.rightList.map((z, i) => {
                                                                if (Array.isArray(z)) {
                                                                    return <ul>{z.map((z, zi) => <li>{z}</li>)} </ul>
                                                                } else return <li>{z}</li>
                                                            })}</ul>
                                                        </div></> :
                                                        <div className="safety__info--col">
                                                            <ul>{y.list.map((z, i) => {
                                                                if (Array.isArray(z)) {
                                                                    return <ul className='sublist'>{z.map((z, zi) => <li>{z}</li>)} </ul>
                                                                } else if (z.includes('www.')) {
                                                                    return <a href={z} target='_blank'>
                                                                        <li>{z}</li>
                                                                    </a>
                                                                } else return <li>{z}</li>
                                                            })}
                                                            </ul>
                                                        </div>
                                                }
                                            </div>
                                        })
                                    }
                                </div>
                                {x.footerDescription && <div className="safety__info-desc"><i>{x.footerDescription}</i></div>}
                            </div>
                        </div>
                    </div>

                </div>
            })
        }
    </div>
}
