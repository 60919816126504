import primaryBraveImage from '../site-components/images/projects/project-covers/BRAVES STADIUM B PIC 2.jpg';
import primaryPlatformImage from '../site-components/images/projects/project-covers/PLATFORM UPGRADE A.jpg';
import primarySubmarImage from '../site-components/images/projects/project-covers/SUBMAR MATTING B.jpg';

import primary8Image from '../site-components/images/projects/project-covers/tank_page_cover.JPG';
//no images currently defined for 4, 5, 6, 7, 9, 10
//TODO: images are expected to be same aspect ratio (~800x600, using placeholder below for other projects at the moment)
import placeholderImage from '../site-components/svgs/project_placeholder.svg';

const PortfolioConfig = {
    title: "PROJECT EXAMPLES",
    desc: "See how Superior Land Designs has helped some of our public and private sector clients.",
    navigationText: "Back to Project Examples",
    primaryImages: [
        {
            id: 1,
            project: 'Atlanta Braves - Truist Park Line Relocation',
            src: primaryBraveImage,
            directory: `braves-stadium`,
        },
        {
            id: 2,
            project: 'Tank Farm Platform Upgrade',
            src: primaryPlatformImage,
            directory: `platform-upgrade`,
        },
        {
            id: 3,
            project: 'Creek Crossing Submar Matting',
            src: primarySubmarImage,
            directory: `submar-matting`,
        },
        {
            id: 4,
            project: 'Facility Upgrades',
            src: placeholderImage,
            directory: `04-facility-upgrades`,
        },
        {
            id: 5,
            project: 'PTI Airport Relocation',
            src: placeholderImage,
            directory: `05-pti-airport-relocation`,
        },
        {
            id: 6,
            project: 'Recoating Pipelines',
            src: placeholderImage,
            directory: `06-recoating-pipelines`,
        },
        {
            id: 7,
            project: 'Tank Dike Surveys',
            src: placeholderImage,
            directory: `07-tank-dike-surveys`,
        },
        {
            id: 8,
            project: 'Tank Projects',
            src: primary8Image,
            directory: `08-tank-projects`,
        },
        {
            id: 9,
            project: 'Western Wake Parkway',
            src: placeholderImage,
            directory: `09-western-wake-pkwy`,
        },
        {
            id: 10,
            project: 'Western Urban Loop',
            src: placeholderImage,
            directory: `10-western-urban-loop`,
        },
        {
            id: 11,
            project: 'Nashville Stadium Relocation',
            src: placeholderImage,
            directory: `nashville-stadium-relocation`,
        },
    ]
}

export default PortfolioConfig